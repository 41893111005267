import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@app/api/services/base/api.service";
import {BaseResponse} from "@app/api/models/base/api.model";
import {
  IUser,
  IUserCreditLack,
  IUserLineMagfaWhois, IUserLineReady,
  IUserLoginLog,
  IUserLoginSetting
} from "@app/api/models/components/user/user.model";
import {
  IUserCreditLackPayload,
  IUserDeactivatePayload,
  IUserEmailVerificationPayload, IUserFeedbackPayload,
  IUserHasTwoStepLoginPayload, IUserImagePayload, IUserLineMagfaWhoisOtpPayload,
  IUserLoginLogsPayload, IUserPasswordCreatePayload,
  IUserPasswordPayload,
  IUserValidIpPayload,
  IUserValidMobilePayload
} from "@app/api/models/components/user/user-payload.model";
import {PagedDataModel} from "@shared/models/paged-data.model";


@Injectable()
export class UserService {
  constructor(
    private _ApiService: ApiService,
  ) {
  }

  user(): Observable<BaseResponse<IUser>> {
    const path = `user`;
    return this._ApiService.get({path});
  }

  deleteUserImage(): Observable<BaseResponse<boolean>> {
    const path = `user/image`;
    return this._ApiService.delete({path});
  }

  updateUserImage(payload: IUserImagePayload): Observable<BaseResponse<any>> {
    const path = `user/image`;
    return this._ApiService.postFile({path, body: payload.body});
  }

  loginLogs(payload: IUserLoginLogsPayload): Observable<BaseResponse<PagedDataModel<IUserLoginLog>>> {
    const path = `user/login-logs`;
    return this._ApiService.get({path, queryParams: payload.params});
  }

  emailVerification(payload: IUserEmailVerificationPayload): Observable<BaseResponse<boolean>> {
    const path = `user/email-verification`;
    return this._ApiService.post({path, body: payload.body});
  }

  createPassword(payload: IUserPasswordCreatePayload): Observable<BaseResponse<boolean>> {
    const path = `user/password`;
    return this._ApiService.post({path, body: payload.body});
  }

  updatePassword(payload: IUserPasswordPayload): Observable<BaseResponse<boolean>> {
    const path = `user/password`;
    return this._ApiService.put({path, body: payload.body});
  }

  deactivate(payload: IUserDeactivatePayload): Observable<BaseResponse<boolean>> {
    const path = `user`;
    return this._ApiService.delete({path, body: payload.body});
  }

  creditLack(): Observable<BaseResponse<IUserCreditLack>> {
    const path = `user/credit-lack-warning-setting`;
    return this._ApiService.get({path});
  }


  updateCreditLack(payload: IUserCreditLackPayload): Observable<BaseResponse<boolean>> {
    const path = `user/credit-lack-warning-setting`;
    return this._ApiService.put({path, body: payload.body});
  }

  createValidMobile(payload: IUserValidMobilePayload): Observable<BaseResponse<boolean>> {
    const path = `user/login-setting/valid-mobile`;
    return this._ApiService.post({path, body: payload.body});
  }

  deleteValidMobile(payload: IUserValidMobilePayload): Observable<BaseResponse<boolean>> {
    const path = `user/login-setting/valid-mobile`;
    return this._ApiService.delete({path, body: payload.body});
  }

  createValidIp(payload: IUserValidIpPayload): Observable<BaseResponse<boolean>> {
    const path = `user/login-setting/valid-ip`;
    return this._ApiService.post({path, body: payload.body});
  }

  deleteValidIp(payload: IUserValidIpPayload): Observable<BaseResponse<boolean>> {
    const path = `user/login-setting/valid-ip`;
    return this._ApiService.delete({path, body: payload.body});
  }

  loginSetting(): Observable<BaseResponse<IUserLoginSetting>> {
    const path = `user/login-setting`;
    return this._ApiService.get({path});
  }

  updateHasTwoStepLogin(payload: IUserHasTwoStepLoginPayload): Observable<BaseResponse<boolean>> {
    const path = `user/login-setting/has-two-step-login`;
    return this._ApiService.put({path, body: payload.body});
  }

  lineMagfaWhois(id: number): Observable<BaseResponse<boolean>>{
    const path = `user/line/${id}/magfa-whois`;
    return this._ApiService.get({path});
  }

  lineMagfaWhoisVerification(id: number): Observable<BaseResponse<IUserLineMagfaWhois>>{
    const path = `user/line/${id}/magfa-whois`;
    return this._ApiService.post({path, body: null});
  }

  lineMagfaWhoisOtp(payload: IUserLineMagfaWhoisOtpPayload): Observable<BaseResponse<IUserLineMagfaWhois>>{
    const path = `user/line/${payload.id}/magfa-whois`;
    return this._ApiService.patch({path, body: payload.body});
  }

  lineReady(): Observable<BaseResponse<IUserLineReady[]>>{
    const path = `user/line/ready`;
    return this._ApiService.get({path});
  }

  feedback(payload: IUserFeedbackPayload): Observable<BaseResponse<any>>{
    const path = `user/feedback`;
    return this._ApiService.post({path, body: payload.body});
  }
}
